import type { FC } from 'react';
import React from 'react';
import type { MessageDescriptor } from 'react-intl-next';
import { defineMessages, FormattedMessage } from 'react-intl-next';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';

import { ItemWrapper } from './PoweredByTemplateCard';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TemplateErrorHeadingWrapper = styled.div({
	font: token('font.body'),
	fontWeight: token('font.weight.semibold'),
	color: token('color.text.subtle'),
	marginBottom: token('space.100'),
	alignSelf: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TemplateErrorDescriptionWrapper = styled.div({
	color: token('color.text.subtlest'),
	alignSelf: 'center',
});

const i18n = defineMessages({
	templateDeletedHeading: {
		id: 'template-card.powered-by-template-card.template-deleted-heading',
		defaultMessage: 'Well, this is awkward...',
		description: 'heading for card when a template is deleted',
	},
	templateDeletedDescription: {
		id: 'template-card.powered-by-template-card.template-deleted-description',
		// TODO: replace straight quotes with curly quotes (see go/curlyquotes)
		// eslint-disable-next-line no-restricted-syntax
		defaultMessage: "That template's been deleted",
		description: 'description for card when a template is deleted',
	},
	unknownErrorHeading: {
		id: 'template-card.powered-by-template-card.unknown-error-heading',
		// TODO: replace straight quotes with curly quotes (see go/curlyquotes)
		// eslint-disable-next-line no-restricted-syntax
		defaultMessage: "Can't load template",
		description: 'heading for card when an unknown error occurs',
	},
	unknownErrorDescription: {
		id: 'template-card.powered-by-template-card.unknown-error-description',
		defaultMessage: 'Try refreshing the page',
		description: 'description for card when an unknown error occurs',
	},
	permissionErrorHeading: {
		id: 'template-card.powered-by-template-card.permission-error-heading',
		defaultMessage: 'Well, this is awkward...',
		description: 'heading for card when a permission error occurs',
	},
	permissionErrorDescription: {
		id: 'template-card.powered-by-template-card.permission-error-description',
		// TODO: replace straight quotes with curly quotes (see go/curlyquotes)
		// eslint-disable-next-line no-restricted-syntax
		defaultMessage: "You can't use this template.",
		description: 'description for card when a permission error occurs',
	},
});

type ErrorState = 'deleted' | 'unknown' | 'permission';
type ErrorCardComponentProps = {
	errorState: ErrorState;
};

type ResolveErrorMessageDescriptor = {
	errorHeading: MessageDescriptor;
	errorDescription: MessageDescriptor;
};
const resolveErrorMessage = (templateError: ErrorState): ResolveErrorMessageDescriptor => {
	switch (templateError) {
		case 'deleted':
			return {
				errorHeading: i18n.templateDeletedHeading,
				errorDescription: i18n.templateDeletedDescription,
			};
		case 'permission':
			return {
				errorHeading: i18n.permissionErrorHeading,
				errorDescription: i18n.permissionErrorDescription,
			};
		default:
			return {
				errorHeading: i18n.unknownErrorHeading,
				errorDescription: i18n.unknownErrorDescription,
			};
	}
};

export const PoweredByTemplateErrorCard: FC<ErrorCardComponentProps> = ({ errorState }) => {
	const { errorHeading, errorDescription } = resolveErrorMessage(errorState);

	return (
		<ItemWrapper>
			<TemplateErrorHeadingWrapper>
				<FormattedMessage {...errorHeading} />
			</TemplateErrorHeadingWrapper>
			<TemplateErrorDescriptionWrapper>
				<FormattedMessage {...errorDescription} />
			</TemplateErrorDescriptionWrapper>
		</ItemWrapper>
	);
};
